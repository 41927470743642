module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='  ';

  var createTR = function(row) {
    var mandatory = '';
    if (row.mandatory) {
      mandatory = '*';
    }
    
__p+='\n    <tr class="tableRow" id="'+
((__t=('row_' + row.id))==null?'':__t)+
'">\n      <th class="collectionTh">'+
((__t=(row.label))==null?'':__t)+
''+
((__t=(mandatory))==null?'':__t)+
':</th>\n      <th class="collectionTh"'+
((__t=(row.info?'':' colspan="2"'))==null?'':__t)+
'>\n        ';

        switch(row.type) {
        case "text":
          
__p+='\n          <input type="text" id="'+
((__t=(row.id))==null?'':__t)+
'" value="'+
((__t=(row.value||''))==null?'':__t)+
'" placeholder="'+
((__t=(row.placeholder||''))==null?'':__t)+
'"></input>\n          ';

          break;
        case "blob":
          
__p+='\n          <textarea id="'+
((__t=(row.id))==null?'':__t)+
'" placeholder="'+
((__t=(row.placeholder||''))==null?'':__t)+
'">'+
((__t=(row.value||''))==null?'':__t)+
'</textarea>\n          ';

          break;
        case "password":
          
__p+='\n          <input type="password" id="'+
((__t=(row.id))==null?'':__t)+
'" value="'+
((__t=(row.value||''))==null?'':__t)+
'" placeholder="'+
((__t=(row.placeholder||''))==null?'':__t)+
'"></input>\n          ';

          break;
        case "readonly":
          
__p+='\n          <div class="modal-text" id="'+
((__t=(row.id))==null?'':__t)+
'">'+
((__t=(row.value||''))==null?'':__t)+
'</div>\n          ';

          break;
        case "checkbox":
          var checked = '',
            disabled = '';
          if (row.checked) {
            checked = 'checked';
          }
          if (row.disabled) {
            disabled = 'disabled';
          }
          
__p+='\n          <input type="checkbox" id="'+
((__t=(row.id))==null?'':__t)+
'" value="'+
((__t=(row.value))==null?'':__t)+
'" '+
((__t=(checked))==null?'':__t)+
' '+
((__t=(disabled))==null?'':__t)+
'></input>\n          ';

          break;
        case "select":
          
__p+='\n          <select id="'+
((__t=(row.id))==null?'':__t)+
'" class="modalSelect">\n          ';
 _.each(row.options, function(opt) { 
__p+='\n            <option value="'+
((__t=(opt.value))==null?'':__t)+
'" '+
((__t=(row.selected === opt.value?"selected":""))==null?'':__t)+
'>'+
((__t=(opt.label))==null?'':__t)+
'</option>\n          ';
 }); 
__p+='\n          </select>\n          ';

          break;
        case "select2":
          
__p+='\n          <input type="hidden" id="'+
((__t=(row.id))==null?'':__t)+
'" value="'+
((__t=(row.value||''))==null?'':__t)+
'" placeholder="'+
((__t=(row.placeholder||''))==null?'':__t)+
'"></input>\n          ';
 if (row.addDelete) {
__p+='\n            <button class="graphViewer-icon-button gv-icon-small add"></button>\n          ';
 } 
__p+='\n          ';
 if (row.addDelete) {
__p+='\n            <button class="graphViewer-icon-button gv_internal_remove_line gv-icon-small delete"></button>\n          ';
 } 
__p+='\n          ';

          break;
        }
        
__p+='\n        ';
 if (row.info) { 
__p+='\n        </th>\n        <th>\n          <span class="modalTooltips arangoicon icon_arangodb_info" title="'+
((__t=(row.info))==null?'':__t)+
'">\n          </span>\n        ';
 } 
__p+='\n      </th>\n    </tr>\n    ';

  };
  
__p+='\n  ';
 if (content) { 
__p+='\n    <table>\n      <tbody>\n        ';

        _.each(content, function(row) {
          createTR(row);
        });
        
__p+='\n      </tbody>\n    </table>\n  ';
 } 
__p+='\n  ';
 if (info) { 
__p+='\n    '+
((__t=( info ))==null?'':__t)+
'\n  ';
 } 
__p+='\n  ';
 if (advancedContent) { 
__p+='\n    <div class="accordion" id="accordion2">\n      <div class="accordion-group">\n        <div class="accordion-heading">\n          <a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion2" href="#collapseOne">\n            <span>'+
((__t=(advancedContent.header))==null?'':__t)+
'</span><span><b class="caret"></b></span>\n          </a>\n        </div>\n        <div id="collapseOne" class="accordion-body collapse out">\n          <div class="accordion-inner">\n            <table>\n              <tbody>\n                ';

                _.each(advancedContent.content, function(row) {
                  createTR(row);
                });
                
__p+='\n              </tbody>\n            </table>\n          </div>\n        </div>\n      </div>\n    </div>\n  ';
 } 
__p+='\n';
}
return __p;
};
