/* jshint browser: true */

/* jshint strict: false, unused: false */

/* global Backbone, window, arangoDocumentModel, $, arangoHelper */
window.ArangoDocument = Backbone.Collection.extend({
  url: '/_api/document/',
  model: arangoDocumentModel,
  collectionInfo: {},
  deleteEdge: function deleteEdge(colid, dockey, callback) {
    this.deleteDocument(colid, dockey, callback);
  },
  deleteDocument: function deleteDocument(colid, dockey, callback) {
    var data = {
      keys: [dockey],
      collection: colid
    };
    $.ajax({
      cache: false,
      type: 'PUT',
      contentType: 'application/json',
      data: JSON.stringify(data),
      url: arangoHelper.databaseUrl('/_api/simple/remove-by-keys'),
      success: function success() {
        callback(false);
      },
      error: function error() {
        callback(true);
      }
    });
  },
  addDocument: function addDocument(collectionID, key) {
    var self = this;
    self.createTypeDocument(collectionID, key);
  },
  createTypeEdge: function createTypeEdge(collectionID, from, to, key, callback) {
    var newEdge;

    if (key) {
      newEdge = JSON.stringify({
        _key: key,
        _from: from,
        _to: to
      });
    } else {
      newEdge = JSON.stringify({
        _from: from,
        _to: to
      });
    }

    $.ajax({
      cache: false,
      type: 'POST',
      url: arangoHelper.databaseUrl('/_api/document?collection=' + encodeURIComponent(collectionID)),
      data: newEdge,
      contentType: 'application/json',
      processData: false,
      success: function success(data) {
        callback(false, data);
      },
      error: function error(data) {
        callback(true, data._id, data.responseJSON);
      }
    });
  },
  createTypeDocument: function createTypeDocument(collectionID, key, callback, returnNew, smartJoinAttribute, smartJoinAttributeValue, smartGraphAttribute, smartGraphAttributeValue) {
    var newDocument = {};

    if (smartJoinAttribute && smartJoinAttributeValue && key) {
      // case: smartJoin, bot value are needed and NOT optional
      newDocument._key = smartJoinAttributeValue + ':' + key;
      newDocument[smartJoinAttribute] = smartJoinAttributeValue;
    } else if (smartGraphAttribute && smartGraphAttributeValue) {
      // case: smartGraph with value
      // other to smartJoin, we can:
      // 1.) Create without smartGraphAttribute and without smartGraphAttributeValue
      // 2.) Create only with smartGraphAttributeValue
      if (key) {
        newDocument._key = smartGraphAttributeValue + ':' + key;
      }

      newDocument[smartGraphAttribute] = smartGraphAttributeValue;
    } else if (key) {
      newDocument._key = key;
    }

    newDocument = JSON.stringify(newDocument);
    var url = arangoHelper.databaseUrl('/_api/document?collection=' + encodeURIComponent(collectionID));

    if (returnNew) {
      url = url + '?returnNew=true';
    }

    $.ajax({
      cache: false,
      type: 'POST',
      url: url,
      data: newDocument,
      contentType: 'application/json',
      processData: false,
      success: function success(data) {
        if (returnNew) {
          callback(false, data);
        } else {
          callback(false, data._id);
        }
      },
      error: function error(data) {
        callback(true, data._id, data.responseJSON);
      }
    });
  },
  getCollectionInfo: function getCollectionInfo(identifier, callback, toRun) {
    var self = this;
    $.ajax({
      cache: false,
      type: 'GET',
      url: arangoHelper.databaseUrl('/_api/collection/' + encodeURIComponent(identifier) + '?' + arangoHelper.getRandomToken()),
      contentType: 'application/json',
      processData: false,
      success: function success(data) {
        self.collectionInfo = data;
        callback(false, data, toRun);
      },
      error: function error(data) {
        callback(true, data, toRun);
      }
    });
  },
  getEdge: function getEdge(colid, dockey, callback) {
    this.getDocument(colid, dockey, callback);
  },
  getDocument: function getDocument(colid, dockey, callback) {
    var self = this;
    this.clearDocument();
    var data = {
      keys: [dockey],
      collection: colid
    };
    $.ajax({
      cache: false,
      type: 'PUT',
      url: arangoHelper.databaseUrl('/_api/simple/lookup-by-keys'),
      contentType: 'application/json',
      data: JSON.stringify(data),
      processData: false,
      success: function success(data) {
        if (data.documents[0]) {
          self.add(data.documents[0]);

          if (data.documents[0]._from && data.documents[0]._to) {
            callback(false, data, 'edge');
          } else {
            callback(false, data, 'document');
          }
        } else {
          self.add(true, data);
          callback(true, data, colid + '/' + dockey);
        }
      },
      error: function error(data) {
        self.add(true, data);
        arangoHelper.arangoError('Error', data.responseJSON.errorMessage + ' - error number: ' + data.responseJSON.errorNum);
        callback(true, data, colid + '/' + dockey);
      }
    });
  },
  saveEdge: function saveEdge(colid, dockey, from, to, model, callback) {
    this.saveDocument(colid, dockey, model, callback, from, to);
  },
  saveDocument: function saveDocument(colid, dockey, model, callback, from, to) {
    if (typeof model === 'string') {
      try {
        model = JSON.parse(model);
      } catch (e) {
        arangoHelper.arangoError('Could not parse document: ' + model);
      }
    }

    model._key = dockey;
    model._id = colid + '/' + dockey;

    if (from && to) {
      model._from = from;
      model._to = to;
    }

    $.ajax({
      cache: false,
      type: 'PUT',
      url: arangoHelper.databaseUrl('/_api/document/' + encodeURIComponent(colid) + '?returnNew=true'),
      data: JSON.stringify([model]),
      contentType: 'application/json',
      processData: false,
      success: function success(data) {
        callback(false, data);
      },
      error: function error(data) {
        callback(true, data);
      }
    });
  },
  updateLocalDocument: function updateLocalDocument(data) {
    this.clearDocument();
    this.add(data);
  },
  clearDocument: function clearDocument() {
    this.reset();
  }
});